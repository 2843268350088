.notification_show {
  z-index: 105;
  position: fixed;
  inset: 0px 0px auto auto;
  margin: 0px;
  transform: translate(-119px, 65px);
}
.user_show {
  z-index: 105;
  position: fixed;
  inset: 0px 0px auto auto;
  margin: 0px;
  transform: translate(-35px, 65px);
}
@media only screen and (max-width: 600px) {
  .notification_show {
    transform: translate(-5px, 65px);
  }
}
@media only screen and (max-width: 600px) {
  .user_show {
    transform: translate(-5px, 65px);
  }
}
